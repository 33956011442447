<template>
  <nut-navbar
    @on-click-back="returnClick()"
    title="隐私政策"
  ></nut-navbar>
  <div class="content">
    <div class="detail-box">
      <h4 class="title">三、账户安全</h4>

      <p>本版发布日期：2021年5月25日</p>
      <p>生效日期：2021年5月26日</p>
      <p>本政策将帮助您了解以下内容：</p>
      <p>概述</p>
      <p>一、我们如何收集个人信息及各项权限说明</p>
      <p>二、我们如何使用Cookie、Beacon、Proxy等技术</p>
      <p>三、我们如何存储和保护个人信息</p>
      <p>四、我们如何使用个人信息</p>
      <p>五、您如何管理自己的个人信息</p>
      <p>六、未成年人个人信息保护</p>
      <p>七、通知和修订</p>
      <p>八、如何联系我们</p>
      <p>九、申诉渠道</p>
      <p>十、数据共享与披露</p>
      <p>《用户注销协议》</p>
      <p>本政策的适用及更新</p>

      <p>
        山东山大华天软件有限公司（注册地址：中国山东省济南市高新区新泺大街2117号铭盛大厦15层，0531-88879088）尊重并保护您的隐私。您使用我们的服务时，我们将按照SView隐私权政策（以下简称“本政策”）收集、存储、使用及对外提供您的个人信息。同时，我们会通过本政策向您说明，我们如何为您提供访问、更新、管理和保护您的信息的服务。本政策与您使用我们的服务关系紧密，我们建议您仔细阅读并理解本政策全部内容，做出您认为适当的选择。我们努力用通俗易懂、简明扼要的文字表达，并对本政策中与您的权益存在重大关系的条款，采用粗体字进行标注以提示您注意。
        为了遵守国家法律法规及监管规定（例如：进行实名制管理、互联网信息管理、采取风险防范措施），也为了向您提供服务及提升服务质量（例如：支持我们开发新产品或完善已有产品功能，为您提供和推荐更为优质或适合的服务），我们需要收集、存储、使用及对外提供您的信息。您同意我们按照本政策约定处理您的信息，以便您享受优质、便捷、个性化的服务，同时更好地保护您的账户及内容安全。
      </p>
      <h4 class="title">一、我们如何收集个人信息</h4>
      <p>
        我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，手机和使用您在服务过程中主动提供或产生的个人信息，帮助您成为我们的用户，向您提供产品/服务，为您优化用户体验，为您提供安全保障。
      </p>
      <h4 class="title">（一）帮助您成为我们的用户</h4>
      <p>为遵守法律法规的要求，以及向您提供更个性化、更便捷的服务，在您注册成为SView用户时，您需要至少提供手机号码以创建SView账号，并完善相关的网络身份识别信息（如头像、昵称及登录密码等）；如果您使用平台提供的任意功能，因SView提供模型的云转换，须识别用户身份，则必须注册成为我们的用户以及提供上述信息，并登录使用所有功能。
      在提供账号信息的过程中，如果您愿意额外补充如下个人信息，将有助于我们向您提供更为个性化的服务：包括您的常居地、毕业院校、专业、工作单位、喜好等。如果您不提供这些信息，不会影响您使用SView的基本功能。
      </p><h4 class="title">（二）向您提供产品\服务</h4>
      <h4 class="title">1. 您向我们提供的个人信息</h4>
      <p>
        浏览模型：须提供联系人信息，包括头像、昵称、手机号、邮箱等，其中手机号为必填。
      </p>
      <p>
        配送/邮寄：在SView上购买实物服务及3D打印服务或享受平台的用户福利，如实物礼品等，须提供收货人姓名、手机号、收货地址、门牌号等，以便于您的订单能够安全准确送达并完成。
      </p>
      <p>
        信息发布：您在SView
        上使用模型上传、转换、下载、浏览、分享时，我们将在服务器存储您转换后的轻量化模型，并不存储原始模型，轻量化模型用于您后续快捷浏览该模型。
      </p>
      <p>
        支付：您在SView上购买服务，包括SView
        提供的个人空间容量、视频会议、装配云转换、天空盒（后续推出服务）等功能，您可以选择系统提供的第三方支付机构（如微信支付、Apple
        Pay、支付宝等支付通道）所提供的支付服务。支付服务本身并不收集您的个人信息，但我们需要将您的SView订单信息及对账信息与这些支付机构共享以确认您的支付指令并完成支付。
      </p>
      <p>
        客服：当您与我们的客服取得联系时，我们的系统可能会记录您与客服之间的通讯记录，以及您的账号信息以便核验身份；当您需要我们提供与您的使用的服务时，如模型检测、模型手动转换、模型修补或调整等适当的帮助和处理，您可能还需要提供上述信息外的其他信息以便完成服务。对于技术人员协助补充或者转换的模型，在完成工作后立即删除，在征得您同意后，在授权范围内使用模型或文档用于宣传或其他用途，客服与您的通讯记录可视为有效授权记录。
      </p>
      <p>
        分享：当您使用分享功能，分享模型及相关信息至社交平台，如微信、QQ、Facebook、Twitter等，我们将收集您发布的信息，并展示您的昵称、头像、轻量化模型、模型快照、模型简介、格式、大小等信息，以便在社交平台更好的展示您发布的内容。
      </p>

      <p>
        您在SView中上传的模型及相关介绍，可能涉及到您所在单位及个人，或者合作伙伴的知识产权、工作成果、商业机密等，这些都属于敏感信息，请您谨慎分享或转发，我们也将在云转换、存储这些信息时，在保证信息可用性的基础上尽量做加密和去标示化处理。
      </p>
      <h4 class="title">2. 我们在您使用服务过程中可能收集的个人信息</h4>
      <p>
        为了满足法律法规及提供服务的基本要求，保障您的账号安全与系统运行安全，我们会收集您在使用我们服务过程中产生的相关信息，包括：
      </p>
      <p>
        ①　日志信息。当您使用我们提供的服务时，我们会自动收集您使用我们服务的详细情况，并作为有关的网络日志保存，包括但不限于您的IP地址、浏览器的类型、使用的语言、操作系统的版本、访问的日期和时间、及您请求的网页记录等信息。
      </p>
      <p>
        ②　照片/摄像头信息。当您使用SView提供的协同会议，需要开启手机、PAD等便携设备的摄像头和语音功能。
      </p>
      <p>请您注意，大多数移动设备都允许您关闭摄像头和语音，具体方法建议您参考或联系您移动设备的服务商或生产商，一旦您通过系统授权关闭定位功能，停止我们对您位置信息的收集，您将可能无法使用我们基于地理位置为您提供的服务，或者无法达到相关服务的预期效果。
     </p> <p>
        ③　设备信息。SView浏览模型，因设备适配会影响模型打开速度及完整性等，我们可能会根据您在软件安装及使用中的具体权限，接收并记录您在接受我们服务过程中使用的相关设备信息，例如设备型号、唯一设备标识符、操作系统、分辨率、电信运营商等软硬件信息等。
        对于从您使用相同SView账号登录各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。
      </p>
      <p>各项权限说明：</p>
      <p>
        1.
        手机存储权限。为给您提供浏览三维文件服务，我们可能会申请手机存储权限。
      </p>
      <p>
        2.
        摄像头权限、麦克风权限。为给您提供协同会议、扫一扫功能，我们可能会申请摄像头权限、麦克风权限。
      </p>
      <p>
        3. 电话权限。为给您提供App内直接拨打电话的功能，我们可能会申请电话权限。
      </p>
      <p>
        4.
        定位权限。为给您提供文件传输功能，我们可能会申请定位权限，仅用于获取WiFi信息，不会收集精确位置信息。
      </p>
      <p>
        5.
        手机通讯录权限。为给您提供添加通讯录好友功能，我们可能会申请手机通讯录权限。
      </p>
      <p>
        6.
        设备信息与日志信息。在仅浏览时，为保障服务所必需，我们会收集设备信息与日志信息用于消息推送和统计。
      </p>

      <h4 class="title">3. 我们可能间接收集到的您的个人信息</h4>
      <p>
        ①　在您使用过程中，因为模型转换和浏览等形成的数据统计，并与之相关的服务改进中涉及的个人信息，尽量避免搜集，但不限于将整体统计数据作为业务分析、融资上市、宣传推广使用。
      </p>
      <p>
        ②　在SView中系统提供的第三方提供的产品或服务，经您授权后向SView的关联方、合作伙伴等获得您的有关信息，并对其信息来源的合法性进行确认后使用您的这些信息。
      </p>
      <p>
        ③　如您在授权使用第三方账号登录时，我们会从第三方获取您共享的账号信息（如头像、昵称等），并在您同意本隐私政策后将您的第三方账户与SView账户进行绑定。
      </p>
      <p>④　改进我们的产品/服务</p>
      <p>
        为维护、改进我们的产品或服务质量，向您提供个性化的服务，我们可能会在符合法律规定并根据您具体授权的情况下收集并使用如下信息：
      </p>
      <p>
        1)
        我们可能会收集您的浏览（模型格式、大小）及搜索信息、行为偏好（使用时间、使用时长、分享渠道）、以及将您在SView使用某项服务中提供的信息与来自其他服务中的信息结合起来，进行综合统计、分析以形成用户画像，用来向您推荐或展示您可能感兴趣的产品\服务信息，或者通过系统向您展示个性化的第三方推广信息。包括在App页面向您推送消息通知，为您提供智能推荐，给您发送推广短信等。
      </p>
      <p>
        2)
        如您不希望收到App页面上的消息通知，可以在首次安装App时拒绝接收消息通知，或者在移动端操作系统中的“通知”中心关掉对应的通知功能；如您不希望接受我们的智能推荐服务，可以自主选择浏览模型及信息、添加好友等；如您不希望接收我们给您发送的推广短信，可通过信息中相应的退订功能进行退订。
      </p>
      <p>
        3)
        我们可能会收集您在参与产品/服务调查时主动向我们提供的信息，以及您与我们的关联方、合作伙伴之间互动时提供的相关信息，以便于我们优化客户服务的质量与流程。
      </p>
      <p>
        4)
        我们可能将业务中收集的个人信息用于统计分析和改进运营，例如通过您转换的模型格式、大小、质量等进行统计分析，从而改进我们的产品、服务或营销计划；又如为改进我们系统而进行的软件升级、技术改造、网络维护、故障排除、内部政策与流程制定、生成内部报告等。
      </p>
      <h4 class="title">
        4.根据相关法律法规及国家标准，在以下情形中，我们可能会依法收集并使用您的个人信息无需征得您的同意:
        
      </h4>
      <p>（1）与国家安全、国防安全直接相关的；</p>
      <p>（2）与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>
        （4）出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的；
      </p>
      <p>（5）所收集的个人信息是您自行向社会公众公开的；</p>
      <p>
        （6）从合法公开披露的信息中收集个人信息，例如：合法的新闻报道、政府信息公开等渠道；
      </p>
      <p>（7）根据您的要求签订和履行合同所必需的；</p>
      <p>
        （8）用于维护所提供的服务的安全稳定运行所必需的，例如：发现、处置产品或服务的故障；
      </p>
      <p>（9）法律法规规定的其他情形。</p>
      <h4 class="title">5、其他</h4>
      <p>
        我们在向您提供其他业务功能时，会另行向您说明信息收集的范围与目的，并征得您的同意后方收集提供相应服务所必要的您的信息。我们会按照本政策以及相应的用户协议约定使用、存储、对外提供及保护您的信息；如您选择不提供前述信息，不会影响您使用SView服务。
      </p>
      <h4 class="title">【友盟】</h4>
      <p>
        为了向您提供一键分享服务，我们的产品集成友盟+ U-Share
        SDK，将收集您的设备标识信息（如IMEI/android
        ID/IDFA等）和您需要分享的社交账户公开信息，以便完成一键分享服务。为了您的信息安全，我们已与第三方SDK服务商进行数据安全保密约定，这些公司会严格遵守我们的数据隐私和安全要求。除非得到您的同意，我们不会与其共享您的个人身份信息。为便于您更好地了解【友盟+】采集的数据类型及用途，以及何保护您的个人信息，您可以登陆https://www.umeng.com/policy了解【友盟+】隐私权政策。
      </p>
      <h4 class="title">【个推】</h4>
      <p>
        为了进行消息推送，我们集成了Getui
        SDK，我们可能会将您的设备厂商、设备品牌、
        设备型号、Android版本、Rom信息、设备识别码（如IMEI、Android
        ID、设备序列号、MAC、Android Advertisement
        ID、IMSI等设备相关信息）、应用列表、网络信息
        （包括IP地址、MAC信息、WIFI列表、基站信息）以及位置相关信息（经纬度）提供给浙江每日互动网络科技股份有限公司，用于为您提供推送技术服务，
        详细内容请访问[《个推用户隐私政策》](https://legal.igexin.com/privacy_zh.html)。
      </p>

      <h4 class="title">【极光】</h4>
      <p>
        我们的服务由第三方合作伙伴【极光】提供，在提供该等服务的过程中，第三方合作伙伴需要收集您的相关个人信息，包括设备信息[
        设备信息包括：设备标识符（IMEI、IDFA、Android
        ID、MAC、OAID等相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）]、网络信息[
        网络信息包括：IP地址，WiFi信息，基站信息等相关信息。
        ]、地理位置信息。为向您提供更好的服务、改善技术和提升用户体验，您理解并同意，我们有权在必要范围内向第三方合作伙伴提供您的个人信息，且第三方合作伙伴亦可以基于前述目的自行收集和使用您的个人信息，并在保护您个人信息和隐私权以及符合适用法律法规的前提下，将收集的信息和数据经去标识化或匿名化处理后用于其他服务和用途。当我们要将个人信息用于本政策未载明的其它用途时，会事先通知您并征求您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，也会事先通知您并并征求您的同意。您可以登陆https://www.jiguang.cn/license/privacy了解【极光】隐私政策。
      </p>
      <h4 class="title">二、我们如何使用Cookie、Beacon、Proxy等技术</h4>
      <p>
        为使您获得更轻松的访问体验，您访问SView提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做可帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全状态。这些数据文件可能是Cookie，Flash
        Cookie，您的浏览器或关联应用程序提供的其他本地存储（以下简称“Cookie”）。请您理解，我们的某些服务只能通过使用Cookie才可得到实现。如您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝SView的Cookie。多数浏览器工具条中的“帮助”部分会告诉您怎样防止您的浏览器接受新的Cookie，怎样让您的浏览器在您收到一条新Cookie时通知您或者怎样彻底关闭Cookie。此外，您可以通过改变浏览器附加程序的设置，或通过访问提供商的网页，来关闭或删除浏览器附加程序使用的类似数据（例如：Flash
        Cookie）。但这一举动在某些情况下可能会影响您安全访问SView提供的服务。
        我们网站上还可能包含一些电子图像（以下简称“单像素GIF文件”或“网络Beacon”），使用网络Beacon可以帮助网站计算浏览网页的用户或访问某些Cookie，我们会通过网络Beacon收集您浏览网页活动的信息，例如：您访问的页面地址、您先前访问的援引页面的位置、您的浏览环境以及显示设定。
        如您通过我们的网站或APP，使用了由第三方而非SView提供的服务时，我们无法保证这些第三方会按照我们的要求采取保护措施，为尽力确保您的账号安全，使您获得更安全的访问体验，我们可能会使用专用的网络协议及代理技术（以下简称“专用网络通道”或“网络代理”）。使用专用网络通道，可以帮助您识别到我们已知的高风险站点，减少由此引起的钓鱼、账号泄露等风险，同时更有利于保障您和第三方的共同权益，阻止不法分子篡改您和您希望访问的第三方之间正常服务内容，例如：不安全路由器、非法基站等引起的广告注入、非法内容篡改等。在此过程中，我们也可能会获得和保存关于您计算机的相关信息，例如：IP地址、硬件ID。
      </p>
      <h4 class="title">三、我们如何存储和保护个人信息</h4>
      <p>
        1、我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。如部分产品涉及跨境业务，我们需要向境外机构传输境内收集的相关个人信息的，我们会按照法律法规和相关监管部门的规定执行，并通过签订协议、现场核查等有效措施，要求境外机构为所获得的您的个人信息保密。我们仅在本政策所述目的所必需期间和法律法规及监管规定的时限内保存您的个人信息。
      </p>
      <p>
        2、为保障您的信息安全，我们致力于使用各种安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。例如：通过网络安全层软件（SSL）进行加密传输、信息加密存储、严格限制数据中心的访问、使用专用网络通道及网络代理。同时我们设立了个人信息保护责任部门，建立了相关内控制度，对可能接触到您的信息的工作人员采取最小够用授权原则；对工作人员处理您的信息的行为进行系统监控，不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导。
      </p>
      <p>
        3、我们已制定个人信息安全事件应急预案，使相关责任人掌握岗位职责和应急处置策略和规程。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以APP推送通知、发送邮件/短消息等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。若您的合法权益受损，我们将承担相应的法律责任。
      </p>
      <p>
        4、请您务必妥善保管好您的SView登录名及其他身份要素。您在使用SView服务时，我们会通过您的登录名及其他身份要素来识别您的身份。一旦您泄漏了前述信息，您可能会蒙受损失，并可能产生对您不利的法律后果。如您发现SView登录名及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。
      </p>
      <p>
        5、在您终止使用SView服务后，我们会停止对您的信息的收集和使用，法律法规或监管部门另有规定的除外。如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。
      </p>
      <p>
        6、随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、资产转让或类似的交易，如涉及个人信息的转让，我们会要求受让您个人信息的公司、组织继续接受本隐私政策的约束，否则,我们将要求该公司、组织重新征求您的授权同意。
      </p>
      <p>
        7、您在使用我们服务时自愿发布、公开分享的信息，可能会涉及您或他人的知识产权、商业机密甚至个人敏感信息，如您的分享模型，以及您在评价时选择上传包含知识产权、商业机密甚至个人敏感信息的的文字、图片或视频等各种形式的信息。请您在使用我们的服务时更加谨慎地考虑，是否要发布甚至公开分享相关信息。
      </p>
      <p>
        8、对于您主动公开的模型、文档及相关信息，在授权范围内其他用户可以浏览或有限制的使用，SView不承担传播后所涉及法律问题，如若您自己不慎发布，可向SView管理员举证产权所有，请求管理员协助删除或停止传播，SView将尽可能协助删除，但无法保证完全删除或阻止传播。
      </p>
      <h4 class="title">四、我们如何使用个人信息</h4>
      <p>
        1、为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，或保障您的账户和内容安全，我们会在以下情形中使用您的信息：
      </p>
      <p>（1）实现本政策中“我们如何收集个人信息”所述目的；</p>
      <p>
        （2）为了使您知晓使用SView服务的状态，我们会向您发送服务提醒。您可以在“我的空间”页面查阅用量、上传下载记录、购买记录等信息，并通过“我的消息“了解版本升级、用户活动、购买或订阅状态等。
      </p>
      <p>
        （3）为了保障服务的稳定性与安全性，我们会将您的信息用于身份验证、安全防范、诈骗监测、预防或禁止非法活动、降低风险、存档和备份用途；
      </p>
      <p>（4）根据法律法规或监管要求向相关部门进行报告；</p>
      <p>（5）邀请您参与我们产品或服务有关的客户调研；</p>
      <p>
        （6）我们会对您的信息进行综合统计、分析加工，以便为您提供更加准确、个性、流畅及便捷的服务，或帮助我们评估、改善或设计产品、服务及运营活动等。我们可能根据前述信息向您提供营销活动通知、商业性电子信息或您可能感兴趣的广告，如您不希望接收此类信息，您可按照我们提示的方法选择退订。
      </p>
      <p>
        2、当我们要将信息用于本政策未载明的其他用途时，会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认动作等形式再次征求您的同意。
      </p>
      <h4 class="title">五、您如何管理您的个人信息</h4>
      <p>您对您的个人信息享有以下权利:</p>
      <h4 class="title">
        (一)您有权访问、更正、删除您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式管理您的信息:
      </h4>
      <p>
        账户信息——您可以访问、更正您账户中的个人资料及账号信息、模型上传下载、发布信息、添加好友等，也可以更改您的密码、添加安全信息或关闭您的账户等，您可以通过访问网页及App在设置中执行此类操作。
        SView为每个注册用户提供一定的网络存储空间，您可在登录后自由的上传、转换及下载自己的模型，并可对空间创建文件夹，对文件更改名称、设定分享范围和权限等。
        请注意您个人空间中存储的模型及相关文件若涉及侵犯他人知识产权、工作成果、商业机密、个人隐私所涉及的法律问题，SView均不承担法律责任。经他人举证后，SView管理员有权对侵权内容给予删除。
      </p>
      <h4 class="title">(二)改变您授权同意的范围或撤回授权</h4>
      <p>
        您可以通过在SView
        个人空间，对模型及文档设定共享范围，之前开放授权，然后更改隐私授权后，请您理解，当您执行上述操作后，我们将无法继续为您提供撤回同意或授权所对应的服务，SView无法协助在其他用户中撤回或删除相关模型及文档，但不会影响之后基于您的授权而开展的个人信息处理。
      </p>
      <h4 class="title">(三)注销账户</h4>
      <p>
        您有权注销您的SView账户，注销方式为：在SView个人权益界面（点击底部菜单【我的】进入个人权益界面），【设置】菜单拉到最后-【注销账户】，根据提示进行操作即可注销您的账户。您注销成功后，SView账号将无法登录，且会解除第三方账号的绑定。具体内容可参见注销账户时所提供的《注销SView账号告知书》。
        如果您无法通过上述方式访问、更正或删除您的个人信息以及注销账号，或者如果您认为SView存在违反法律的规定收集、使用您个人信息的情形，您可以通过我们的客服与我们取得联系。为了保障安全，我们可能需要您提供相应方式证明您的身份和请求的正当性，我们将在收到您反馈并验证您的身份后的30天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
      </p>
      <h4 class="title">六、未成年人的个人信息保护</h4>
      <p>
        SView是一款专业的工程技术APP，我们的网站和服务主要面向成人。如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
        对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
        如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
      </p>
      <h4 class="title">七、通知和修订</h4>
      <p>
        为给您提供更好的服务，我们的业务将不时变化，本隐私政策也将随之调整。未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在我们网站、移动端上发出更新版本或以其他方式提醒您相关内容的更新，也请您访问我们以便及时了解最新的隐私政策。在前述情况下，若您继续使用我们的服务，即表示同意接受修订后的本政策并受之约束。
      </p>
      <h4 class="title">八、如何联系我们</h4>
      <p>
        如果您对于我们的个人信息处理行为存在任何投诉举报需求，您可以通过SView网站（如https://sview.sv3d.cn）和App上提供的联系方式\客服系统与我们联系并作充分描述，我们将在验证您身份的30天内答复您的请求并尽力解决。
        如果您对我们的回复不满意，特别是认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼。
      </p>
      <h4 class="title">《用户注销协议》</h4>
      <p>在您注销您的账户之前，请充分阅读、理解并同意下列事项：</p>
      <p>
        在此善意地提醒您，您注销SView账户的行为，将导致我们终止对您提供本服务，也会给您的售后维权带来诸多不便。注销成功后，我们将删除您的个人信息，包含个人空间的所有模型及文档，使其保持不可被检索、访问的状态，或对其进行匿名化处理。您知晓并理解，根据相关法律规定相关交易记录须在SView保存1年甚至更长的时间。
      </p>
      <p>1. 如果您仍执意注销账户，您的账户需同时满足以下条件：</p>
      <p>（1）账户在SView系统中无资产、无重要且自行未进行备份的模型和文档；</p>
      <p>（2）账户内无未完成的订单、已提供服务但未支付的订单/服务；</p>
      <p>（3）账户无任何纠纷，包括投诉举报或被投诉举报；</p>
      <p>（4）账户为正常使用中的账户且无任何账户被限制的记录；</p>
      <p>
        2.
        在SView账户注销期间，如果您的SView账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，SView有权自行终止本您账户的注销而无需另行得到您的同意。
      </p>
      <p>
        3. 请您在提交注销申请前，务必解绑其他相关账户，具体可与我们的客服联系。
      </p>
      <p>
        4.
        SView账户一旦被注销将不可恢复，请您在操作之前自行备份账户相关的所有信息和数据。注销SView账户，您将无法再使用SView账户，也将无法找回SView账户中及与账户相关的任何内容或信息（即使您使用相同的手机号码再次注册并使用SView），包括但不限于：
      </p>
      <p>（1）您将无法登录、使用SView账户；</p>
      <p>
        （2）SView账户的个人资料和历史信息（包括但不限于用户名、昵称、头像、上传、转换、下载、分享记录等）都将无法找回。
      </p>
      <p>
        5. 注销SView账户并不代表本账户注销前的账户行为和相关责任得到豁免或减轻。
      </p>
      <h4 class="title">九、申诉渠道</h4>
      <p>
        申诉渠道：若您对SView及SView所提供服务有任何意见，欢迎垂询APP应用客服中心。SView客户服务电话：0731-88879090，16652006556，服务网站：https://sview.sv3d.cn/
      </p>
      <h4 class="title">十、数据共享与披露</h4>
      <h4 class="title">【友盟】</h4>
      <p>
        为了向您提供一键分享服务，我们的产品集成友盟+ U-Share
        SDK，将收集您的设备标识信息（如IMEI/android
        ID/IDFA等）和您需要分享的社交账户公开信息，以便完成一键分享服务。为了您的信息安全，我们已与第三方SDK服务商进行数据安全保密约定，这些公司会严格遵守我们的数据隐私和安全要求。除非得到您的同意，我们不会与其共享您的个人身份信息。为便于您更好地了解【友盟+】采集的数据类型及用途，以及何保护您的个人信息，您可以登陆https://www.umeng.com/policy了解【友盟+】隐私权政策。
      </p>
      <h4 class="title">【个推】</h4>
      <p>
        为了进行消息推送，我们集成了Getui
        SDK，我们可能会将您的设备厂商、设备品牌、
        设备型号、Android版本、Rom信息、设备识别码（如IMEI、Android
        ID、设备序列号、MAC、Android Advertisement
        ID、IMSI等设备相关信息）、应用列表、网络信息
        （包括IP地址、MAC信息、WIFI列表、基站信息）以及位置相关信息（经纬度）提供给浙江每日互动网络科技股份有限公司，用于为您提供推送技术服务，
        详细内容请访问[《个推用户隐私政策》](https://legal.igexin.com/privacy_zh.html)。
      </p>
      <h4 class="title">【极光】</h4>
      <p>
        我们的服务由第三方合作伙伴【极光】提供，在提供该等服务的过程中，第三方合作伙伴需要收集您的相关个人信息，包括设备信息[
        设备信息包括：设备标识符（IMEI、IDFA、Android
        ID、MAC、OAID等相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）]、网络信息[
        网络信息包括：IP地址，WiFi信息，基站信息等相关信息。
        ]、地理位置信息。为向您提供更好的服务、改善技术和提升用户体验，您理解并同意，我们有权在必要范围内向第三方合作伙伴提供您的个人信息，且第三方合作伙伴亦可以基于前述目的自行收集和使用您的个人信息，并在保护您个人信息和隐私权以及符合适用法律法规的前提下，将收集的信息和数据经去标识化或匿名化处理后用于其他服务和用途。当我们要将个人信息用于本政策未载明的其它用途时，会事先通知您并征求您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，也会事先通知您并并征求您的同意。您可以登陆https://www.jiguang.cn/license/privacy了解【极光】隐私政策。
        本政策的适用及更新
      </p>
      <p>SView所有服务均适用本政策。</p>
      <p>发生下列重大变化情形时，我们会适时对本政策进行更新：</p>
      <p>
        （1）我们的基本情况发生变化，例如：兼并、收购、重组引起的所有者变更；
      </p>
      <p>（2）收集、存储、使用个人信息的范围、目的、规则发生变化；</p>
      <p>（3）对外提供个人信息的对象、范围、目的发生变化；</p>
      <p>（4）您访问和管理个人信息的方式发生变化；</p>
      <p>（5）数据安全能力、信息安全风险发生变化；</p>
      <p>
        （6）用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化；
      </p>
      <p>（7）其他可能对您的个人信息权益产生重大影响的变化。</p>
      <p>
        由于SView的用户较多，如本政策发生更新，我们将以APP推送通知、发送邮件/短消息或者在SView官方网站、微信服务号发布公告的方式来通知您。为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用SView服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。
        您可以在SView网站首页查看本政策，也可在SView APP通过“我的 → 设置 →
        隐私”中查看本政策。我们鼓励您在每次使用SView时都查阅我们的隐私政策。
      </p>
    </div>
    <div class="blank10"></div>
  </div>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'useragreement',
  setup() {
    const router = useRouter()
    //返回登录页面
    const returnClick = () => {
      router.push('/phoneWeb/register')
    }

    return {
      returnClick,
    }
  },
}
</script>
<style scoped>
</style>
